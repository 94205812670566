#testPage {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    * {
        display: flex;
    }

    .w100 {
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
    }
    .w50 {
        width: 50%;
        margin-bottom: 20px;
    }
    .w25 {
        width: 25%;
        margin-bottom: 20px;
    }

    .codeBox {
        width: 50%;
        flex-direction: column;
        min-height: 200px;
        padding: 10px;
        background-color: rgb(118, 117, 130);

        .row {
            outline-style: none;
            padding: 2px 0;
            color: white;
            font-size: 16px;
            caret-color: white;
            border: 1px solid red;
        }
    }

    .codeViewer {
        width: 50%;
        height: 200px;
        border: 1px solid red;
        flex-direction: column;

        * {
            display: flex;
        }

        > .viewHead {
            border-bottom: 1px solid red;
        }

        > .viewBody {
            padding: 10px;
        }
    }


}