#DataStorage {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
        display: flex;
    } 
    .storageBox {
        justify-content: space-between;

        > div {
            justify-content: space-between;
        }
        #loginBox {
            flex-direction: column;
            border: 2px solid #b6b6b6;
            background-color: #ffffff;
            border-radius: 10px;
            padding: 20px;
            width: 100%;
            max-width: 342px;
            min-width: 200px;

            > h2 {
                font-size: 20px;
                font-weight: bolder;
                margin-bottom: 10px;
                align-self: center;
            }
            > h3 {
                font-size: 14px;
                margin-top: 10px;
                align-self: center;
                color: #4d4d4d;
            }
            > input {
                max-width: 300px;
                font-size: 18px;
                padding: 4px 10px;
                margin-bottom: 10px;
                border: 2px solid #b6b6b6;
                background-color: white;
                border-radius: 10px;
            }
            > div {
                display: flex;
                justify-content: space-between;
                > label {
                    display: flex;
                    margin-bottom: 10px;
                    > p {
                        margin-left: 4px;
                    }
                    > input {
                        width: 20px;
                    }
                }
            }
            > button {
                font-size: 16px;
            }
        }
        #cookieBox {
            width: 100%;
            min-width: 400px;
            margin-left: 20px;

            > table {
                background-color: white;
                border-collapse: collapse;
                width: 100%;
                tr {
                    td {
                        border: 2px solid #b6b6b6;
                        font-size: 14px;
                        padding: 6px;
                    }
                    td:nth-child(1) {
                        width: 100px;
                        font-weight: bolder;
                    }
                    td:nth-child(2) {
                        width: 100px;
                    }
                    td:nth-child(3) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow-x: auto;
                    }
                }
            }
        }

        .shoppingBox {
            flex-direction: column;
            border: 2px solid #b6b6b6;
            border-radius: 10px;
            padding: 20px;
            background-color: #ffffff;
            width: 100%;
            max-width: 522px;

            > h2 {
                text-align: center;
                width: 100%;
                font-size: 26px;
                font-weight: bolder;
                padding-bottom: 5px;
                margin-bottom: 10px;
                border-bottom: 2px solid #b6b6b6;
            }
            > .itemBox {
                width: 100%;
                display: flex;
                flex-direction: column;
                > .item {
                    display: flex;
                    width: 100%;
                    max-width: 480px;
                    border: 2px solid #b6b6b6;
                    border-radius: 10px;
                    padding: 10px;
                    background-color: #ffffff;
                    margin-bottom: 10px;
    
                    > img {
                        width: 100px;
                        height: 100px;
                        border-radius: 10px;
                    }
                    > div {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 0 10px;
                        > h1 {
                            font-size: 18px;
                            font-weight: bolder;
                        }
                        > h2 {
                            font-size: 16px;
                            font-weight: bolder;
                            color: #6c6c6c;
                        }
                        
                        > div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            > h3 {
                                font-size: 18px;
                                font-weight: bolder;
                                color: #ff9028;
                            }
                            > input {
                                width: 50px;
                                font-size: 18px;
                                text-align: center;
                                border: 1px solid #B0A695;
                                border-radius: 4px;
                                margin-right: 10px;
                            }
                            > button {
                                font-size: 16px;
                                padding: 4px 8px;
                            }
                        }
                    }
                }
            }
        }
        .cart {
            background-color: #ffffff;
            margin-left: 10px;
        }
    }

    #PopupComponent {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        z-index: 10;
        
        > #Popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            background-color: white;
            border: 2px solid #111;
            overflow: hidden;

            > img {
                display: flex;
                width: 100%;
                min-height: 150px;
                max-height: 560px;
            }

            > div {
                display: flex;
                justify-content: space-between;

                > p {
                    font-size: 20px;
                    padding: 4px;
                    cursor: pointer;
                }

                > p:hover {
                    color: rgb(32, 130, 215);
                    font-weight: bolder;
                }
            }
        }
    }
}
/* 1000px 이하 */
@media (max-width: 1000px) {
}
/* 800px 이하 */
@media (max-width: 800px) {
    #DataStorage > .storageBox > div {
        flex-direction: column;
        #loginBox {
            max-width: 100%;
            width: 100%;

            > input {
                max-width: 100%;
                width: 100%;
            }
        }
        #cookieBox {
            margin: 20px 0 0 0;
        }
        > .shoppingBox {
            padding: 10px;
            max-width: 100%;
            > .itemBox {
                max-width: 100%;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                > .item {
                    max-width: 48%;
                    flex-direction: column;
                    > img {
                        width: 100%;
                        height: auto;
                    }
                    > div {
                        display: flex;
                        padding: 0;
                        > h1 {
                            font-size: 16px;
                        }
                        > h2 {
                            font-size: 14px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin: 4px 0;
                        }

                        > div {
                            margin-top: auto;
                            > h3 {
                                font-size: 16px;
                            }
                            > button {
                                font-size: 14px;
                                padding: 4px 6px;
                            }
                        }
                    }
                }
            }
        }
        > .cart {
            margin: 20px 0 0 0;
        }
    } 
}
/* 500px 이하일 때 */
@media (max-width: 500px) {
    #DataStorage {
        > .storageBox > div {
            > #cookieBox {
                min-width: 300px;
                > table {
                    > tr {
                        > td {
                            font-size: 12px;
                        }
                    }
                }
            }
            > .shoppingBox {
                > .itemBox {
                    > .item {
                        > div {
                            > div {
                                > h3 {
                                    font-size: 14px;
                                }
                                > button {
                                    font-size: 12px;
                                    padding: 2px 4px;
                                }
                            }
                        }
                    }
                }
            }
        } 
        > #PopupComponent {
            > #Popup {
                > div {
                    > p {
                        font-size: 16px;
                        padding: 2px;
                    }
                }
            }
        }
    }
}