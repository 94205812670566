/*** 기본 설정 ***/
* {
    margin: 0;
    padding: 0;
    font: normal 14px 'Noto Sans KR', sans-serif;
}
ul,
ol {
    list-style: none;
}
a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    -webkit-appearance: none;
}
button {
    -webkit-appearance: none;
    border: 0;
    padding: 10px;
    border: 2px solid #ff5f2e;
    color: #ff5f2e;
    background-color: white;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bolder;
    transition: 0.25s;
    cursor: pointer;
    white-space: nowrap;
}
button:hover {
    background-color: #ff5f2e;
    color: #ffffff;
}
input,
textarea {
    outline: none;
    box-sizing: border-box;
}

div {
    box-sizing: border-box;
}
body {
    background-color: white;
    width: 100%;
    overflow-x: hidden !important;
}

#mainLayout {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 0 auto;
}

#header {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    z-index: 2;
    border-bottom: 1px solid #dfdfdf;
    
    div {
        display: flex;
    }

    > #headerBox {
        width: 1200px;
        justify-content: space-between;

        > #logoBox {
            align-items: center;
            padding: 10px 10px 0 10px;

            > a {
                display: flex;
                > img {
                    width: 300px;
                    height: 100px;
                }
            }
        }
        > #navBox {
            padding: 0 10px;
    
            > #nav {
                align-items: end;
                > p {
                    display: flex;
                    font-size: 16px;
                    font-weight: bolder;
                    box-sizing: border-box;
                    border-bottom: 1px solid #dfdfdf;
                    padding: 0 10px 10px 10px;
                    margin-bottom: -1px;
                    white-space: nowrap;
                }
                > button {
                    display: flex;
                    font-size: 16px;
                    font-weight: bolder;
                    box-sizing: border-box;
                    background-color: transparent;
                    color: #000;
                    border: 0;
                    border-bottom: 1px solid #dfdfdf;
                    padding: 0 10px 10px 10px;
                    border-radius: 0;
                    margin-bottom: -1px;
                    cursor: pointer;
                }
                > button:hover {
                    color: rgb(32, 130, 215);
                    font-weight: bolder;
                    border-bottom: 1px solid rgb(32, 130, 215);
                }
            }
        }
    }
}
#footer {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 100%;
    z-index: 2;
    border-top: 1px solid #dfdfdf;

    > div {
        display: flex;
        width: 1200px;
        padding: 20px 0;
        justify-content: space-between;

        > div {
            display: flex;
            flex-direction: column;
            align-self: start;
            padding: 0 20px;

            > h2 {
                font-size: 20px;
                font-weight: bolder;
                padding: 0 0px 10px 0px;
                color: #000;
            }
            > h3 {
                font-size: 14px;
                padding: 2px 0px;
                color: #333333;
            }
            >h4 {
                font-size: 14px;
                font-weight: bolder;
                padding: 2px 0px;
                color: #000;
            }
    
            > div {
                width: 100%;
                display: flex;
                justify-content: space-between;
    
                > .creator {
                    display: flex;
                    flex-direction: column;
        
                    > div {
                        display: flex;
                    }
                    > .name {
                        align-items: center;
        
                        > img {
                            display: flex;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                        > h1 {
                            font-size: 16px;
                            font-weight: bolder;
                            color: #000;
                        }
                    }
                    > .icon {
                        margin-top: 5px;
                        > a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;
                            > img {
                                display: flex;
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }
                > .creator:nth-child(1) {
                    margin-right: 40px;
                }
            }
        }
    }
}

#contents {
    display: flex;
    align-self: center;
    padding: 10px 0;
    width: 100%;
    max-width: 1200px;
    height: auto;

    div {
        box-sizing: border-box;
    }

    >#mainPage {
        width: 100%;
        display: flex;
        flex-direction: column;

        > .pageList {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            > .refBox {
                position: relative;
                flex: 1 1 calc(33.33% - 15px);
                padding: 20px 0;
                transition: background-color 0.3s ease-in-out;
                cursor: pointer;
    
                > .imgBox {
                    width: 85%;
                    display: flex;
                    overflow: hidden;
                    justify-content: center;
                    margin: 0 auto;
                    border-radius: 10px;

                    >img {
                        width: 100%;
                        transition: transform 0.3s ease-in-out;
                    }
                }
    
                > .textBox {
                    display: flex;
                    padding: 10px 0;
                    width: 85%;
                    margin: 0 auto;

                    > .refInfo {
                        display: flex;
                        max-width: 50%;
                        flex-direction: column;
                        border-right: 1px solid #bababa;
                        padding-right: 10px;
    
                        > pre {
                            font-size: 16px;
                            font-weight: bolder;
                            padding-top: 4px;
                        }
                    }
                    
                    > .refTitle {
                        display: flex;
                        flex-direction: column;
                        padding-left: 10px;
                        
                        > h1 {
                            font-size: 22px;
                            font-weight: bolder;
                            padding-bottom: 10px;
                        }
    
                        > h2 {
                            font-size: 14px;
                            margin-bottom: 2px;
                            color: #333333;
                        }
                    }
                }
            }
            >.refBox:hover img {
                transform: scale(1.05);
            }
            >.refBox:hover {
                //background-color: #dfdfdf;
            }
            .refBox:nth-child(3n + 2) {
                border-right: 1px solid #dfdfdf;
                border-left: 1px solid #dfdfdf;
            }

            > .fakeBox {
                flex: 1 1 calc(33.33% - 15px);
                visibility: hidden; /* fakeBox를 보이지 않게 설정 */
            }
        }
    }
}
#inspectionPage{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;

    > div {
        display: flex;
        align-items: center;
        flex-direction: column;

        > img {
            width: 340px;
            height: 340px;
        }

        > div {
            font-size: 30px;
            font-weight: bolder;
        }
        > p {
            font-size: 24px;
            font-weight: bolder;
        }
        > button {
            width: 35%;
            font-size: 20px;
            font-weight: bolder;
            margin: 20px 0;
            border: 2px solid #ad4800;
            border-radius: 10px;
            background-color: #eb6100;
            color: white;
            padding: 10px;
            cursor: pointer;
        }
        > button:hover {
            background-color: #ff7c1f;
        }
    }
}

.ts_box {
    display: flex;
    flex-direction: column;
    padding: 20px;

    h1 {
        font-size: 26px;
        font-weight: bolder;
        margin: 10px 0;
        padding: 2px 6px;
        > span {
            font-size: 26px;
            font-weight: bolder;
        }
    }
    h2 {
        font-size: 20px;
        margin: 6px 0px 6px 8px;
        padding: 2px 6px;
        > span {
            font-size: 20px;
        }
    }
    h3 {
        font-size: 18px;
        margin: 4px 0px 4px 16px;
        padding: 2px 6px;
        > span {
            font-size: 18px;
        }
    }
    h4 {
        font-size: 16px;
        margin: 2px 0px 2px 32px;
        padding: 2px 6px;
        > span {
            font-size: 16px;
        }
    }
    .exam {
        font-size: 14px;
        font-style: italic;
        margin: 2px 0px 2px 40px;
    }
    .bold {
        font-weight: bolder;
    }
    .red {
        color: red;
    }
    .blue {
        color: blue;
    }
    .green {
        color: rgb(0, 135, 0);
    }
    .brown {
        color: brown;
    }
    .yellow {
        color: yellow;
    }
    .orange {
        color: rgb(224, 146, 0);
    }
    .gray {
        color: gray;
    }
    .white {
        color: white;
    }
    .italic {
        font-style: italic;
    }
    .bg_red {
        background-color: #ff6a6a;
        align-self: flex-start;
    }
    .bg_blue {
        background-color: rgb(164, 217, 255);
        align-self: flex-start;
    }
    .bg_green {
        background-color: rgb(139, 242, 139);
        align-self: flex-start;
    }
    .bg_yellow {
        background-color: rgb(255, 255, 158);
        align-self: flex-start;
    }
    .bg_brown {
        background-color: rgb(207, 108, 108);
        align-self: flex-start;
    }
    .bg_orange {
        background-color: orange;
        align-self: flex-start;
    }
    .line {
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .point::before {
        content: '■';
        padding-right: 4px;
    } 
    ul {
        padding-left: 20px;
        margin: 2px 0px 2px 24px;
        li {
            font-size: 16px;
            color: #666;
            line-height: 1.4;
            margin-bottom: 5px;
            list-style-type: disc;
            > span {
                font-size: 16px;
            }
        }
    }
}
.frame {
    display: flex;
    flex-direction: column;
    margin: 20px 20px 40px 20px;
    //background-color: rgb(243, 250, 255);
    background-color: rgb(255, 255, 255);
    //box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4),
        -4px -4px 10px rgba(0, 0, 0, 0.4);
    //border-left: 10px solid rgb(32, 130, 215);
    //border-radius: 10px;
    border-radius: 0;
    overflow: hidden;

    > .frameInfo {
        display: flex;
        //background-color: rgb(201, 230, 255);
        font-size: 20px;
        font-weight: bolder;
        //color: rgb(11, 107, 191);
        color: #ff5f2e;
        padding: 14px;
        margin: 0 20px;
        //border-bottom: 2px solid rgb(154, 208, 255);
        border-bottom: 2px solid #ff5f2e;
    }
    > .frameInfo::before {
        content: "ⓘ";
        margin-right: 6px;
    }
    > .frameBody {
        display: flex;
        padding: 20px;
    }
}

/* 800px 이하 */
@media (max-width: 800px) {
    #contents #mainPage .pageList {
        //width: 800px;
        .refBox, .fakeBox {
            flex: 1 1 calc(50% - 15px) !important;
        }
        .refBox:nth-child(3n + 2) {
            border-right: 0 !important;
            border-left: 0 !important;
        }
        .refBox:nth-child(2n) {
            border-left: 1px solid #dfdfdf !important;
        }
        .frameBody {
            flex-direction: column !important;
        }
    }
    #footer > div > div > div {
        flex-direction: column;
        > .creator {
            margin-bottom: 5px;
            flex-direction: row;
            > .name {
                margin-right: 10px;
            }
            > .icon {
                margin: 0;
            }
        }
    }
    .ts_box {
        padding: 10px;
    }
    .frame {
        margin: 10px;
        > .frameInfo {
            margin: 0 10px;
        }
        > .frameBody {
            padding: 15px 10px;
        }
    }
}
/* 500px 이하일 때 */
@media (max-width: 500px) {
    #contents #mainPage .pageList {

        .refBox, .fakeBox {
            flex: 1 1 100% !important;
            border-right: 0 !important;
            border-left: 0 !important;
        }
    }
    #header > #headerBox > #logoBox > a > img {
        width: 100%;
        height: 100%;
    }
    #footer > div {
        flex-direction: column;
        > div {
            width: 100%;
            > div {
                width: 100%;
                justify-content: space-between;
                flex-direction: row;
                > .creator {
                    margin: 5px 0;
                }
            }
            > h2 {
                padding: 0;
            }
        }
        > div:nth-child(2) {
            margin-top: 20px;
        }
    }
    .ts_box {
        padding: 5px;
    }
    .frame {
        > .frameInfo {
            margin: 0 5px;
        }
        > .frameBody {
            padding: 10px 5px;
        }
    }
}