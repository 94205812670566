#apiPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    > div {
        display: flex;
        width: 100%;
    }
}

#openApiPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    > .section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        > h1 {
            font-size: 30px;
            font-weight: bolder;
        }
        > p {
            width: 80%;
            font-size: 24px;
            font-weight: bolder;
            margin: 20px 0;
        }
        > .serviceOutline {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > .listBox {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #000;
                margin-bottom: 10px;

                > h4 {
                    width: 100%;
                    display: flex;
                    font-size: 20px;
                    font-weight: bolder;
                    justify-content: center;
                    padding: 10px 0;
                    border-bottom: 1px solid #000;
                    background-color: #e9e9e9;
                }
                > div {
                    display: flex;
                    width: 100%;
                    border-bottom: 1px solid #000;
                    
                    > h4, h5 {
                        display: flex;
                        font-size: 18px;
                        padding: 4px 0 4px 10px;
                        align-items: center;
                    }
                    > h4 {
                        width: 30%;
                        border-right: 1px solid #000;
                        font-weight: bolder;
                    }
                    > h5 {width: 70%;}
                }
                > .serviceKey {
                    > div {
                        width: 30%;
                        display: flex;
                        flex-direction: column;
                        border-right: 1px solid #000;
                        padding: 6px 0 6px 10px;
                        box-sizing: content-box;

                        > h4, h5 {
                            width: 100%;
                            display: flex;
                            font-size: 18px;
                            font-weight: bolder;
                        }
                        > button {
                            width: 90%;
                            padding: 4px 8px;
                            font-size: 18px;
                            margin-top: 6px;
                        }
                    }
                    > h5 {
                        display: flex;
                        font-size: 18px;
                        width: 70%;
                        align-items: center;
                    }
                }
                > div:nth-last-child(1) {
                    border: 0;
                }
            }
        }
        > .apiList {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > .listBox {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #000;
                margin-bottom: 10px;

                > h4 {
                    width: 100%;
                    display: flex;
                    font-size: 20px;
                    font-weight: bolder;
                    justify-content: center;
                    padding: 10px 0;
                    border-bottom: 1px solid #000;
                    background-color: #e9e9e9;
                }
                > div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > h5 {
                        width: 40%;
                        height: 100%;
                        display: flex;
                        font-size: 18px;
                        font-weight: bolder;
                        justify-content: center;
                        align-items: center;
                        border-right: 1px solid #000;
                        background-color: #e9e9e9;
                    }
                    > div {
                        width: 60%;
                        display: flex;
                        flex-direction: column;

                        > div {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            padding: 4px 0;
                            border-bottom: 1px solid #000;

                            > h4 {
                                width: 40%;
                                display: flex;
                                font-size: 18px;
                                justify-content: flex-start;
                                padding-left: 10px;
                            }
                            > h5 {
                                width: 60%;
                                display: flex;
                                font-size: 18px;
                                justify-content: flex-start;
                            }
                        }
                        > div:nth-last-child(1) {
                            border: 0;
                        }
                    }
                }
            }
        }
        > .serviceList {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #000;
            margin-bottom: 10px;
            > div {
                display: flex;
                width: 100%;
                border-bottom: 2px solid #000;
                > h3 {
                    display: flex;
                    width: 10%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    font-weight: bolder;
                    border-right: 1px solid #000;
                    background-color: #e9e9e9;
                }
                > div {
                    width: 90%;
                    display: flex;
                    flex-direction: column;

                    > div {
                        width: 100%;
                        display: flex;
                        > h4, h5 {
                            display: flex;
                            font-size: 18px;
                            justify-content: flex-start;
                            white-space: nowrap;
                            padding: 4px 0 4px 10px;
                            border-bottom: 1px solid #000;
                        }
                        > h4 {
                            width: 40%;
                            border-right: 1px solid #000;
                            font-weight: bolder;
                        }
                        > h5 {width: 65%;}
                    }
                    > div:nth-last-child(1) {
                        > h4, h5 {
                            border-bottom: 0;
                        }
                    }
                }
            }
            > div:nth-last-child(1) {
                border: 0;
            }
        }
        > .error {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #000;
            margin-bottom: 10px;
            > h4 {
                width: 100%;
                display: flex;
                font-size: 20px;
                font-weight: bolder;
                justify-content: center;
                padding: 10px 0;
                border-bottom: 1px solid #000;
                background-color: #e9e9e9;
            }
            > .listBox {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                > div {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 4px 0;
                    border-bottom: 1px solid #000;

                    > h4 {
                        width: 60%;
                        display: flex;
                        font-size: 16px;
                        font-weight: bolder;
                        justify-content: flex-start;
                        padding-left: 10px;
                    }
                    > h5 {
                        width: 40%;
                        display: flex;
                        font-size: 16px;
                        justify-content: flex-start;
                    }
                }
                > div:nth-last-child(1) {
                    border: 0;
                }
            }
        }
    }
    > .openApiExam {
        margin-top: 40px;
        * {display: flex;}
        > div {
            width: 100%;
            flex-direction: column;

            > h1 {
                width: fit-content;
                font-size: 20px;
                font-weight: bolder;
                margin-bottom: 10px;
                padding: 6px;
                border-radius: 6px;
                background-color: rgb(139, 242, 139);
            }
            > .searchBox {
                width: 100%;
                margin-bottom: 10px;
                justify-content: space-between;

                > input {
                    width: 70%;
                    font-size: 18px;
                    padding: 6px 10px;
                    border: 2px solid #b6b6b6;
                    background-color: white;
                    border-radius: 10px;
                    margin-right: 10px;
                }
                > button {
                    width: 30%;
                    font-size: 18px;
                    padding: 6px 10px;
                    justify-content: center;
                }
            }
            > .searchBox2 {
                margin-bottom: 10px;
                justify-content: space-between;
                > div {
                    width: 70%;
                    margin-right: 10px;
                    > h2 {
                        width: 30%;
                        font-size: 18px;
                        font-weight: bolder;
                        align-items: center;
                        justify-content: center;
                    }
                    > input {
                        width: 20%;
                        font-size: 18px;
                        padding: 6px 10px;
                        border: 2px solid #b6b6b6;
                        background-color: white;
                        border-radius: 10px;
                    }
                }
                > button {
                    width: 30%;
                    font-size: 18px;
                    padding: 6px 10px;
                    justify-content: center;
                }
            }
            > .searchResult {
                width: 100%;
                border: 1px solid #000;

                > .imgBox {
                    width: 30%;
                    border-right: 1px solid #000;
                    justify-content: center;
                    align-items: center;
                    > img {
                        width: 50%;
                    }
                }
                > .infoBox {
                    width: 70%;
                    flex-direction: column;
                    justify-content: space-between;

                    > div {
                        width: 100%;
                        height: 100%;
                        border-bottom: 1px solid #000;

                        > h4, h5 {
                            width: 25%;
                            height: auto;
                            padding: 4px;
                            font-size: 18px;
                            border-right: 1px solid #000;
                            box-sizing: border-box;
                            align-items: center;
                            justify-content: center;
                        }
                        > h4 {
                            font-weight: bolder;
                            background-color: #e9e9e9;
                        }
                        > h5:nth-last-child(1) {
                            border: 0;
                        }
                    }
                    > div:nth-last-child(1) {
                        border: 0;
                        > h4 {width: 25%;}
                        > h5 {width: 75%;}
                    }
                }
            }
        }
    }
}

/* 800px 이하 */
@media (max-width: 800px) {
    #openApiPage {
        > .section {
            > .apiList {
                > .listBox {
                    width: 100%;
                    > h4 {font-size: 18px;}
                    > div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        > h5 {
                            width: 100%;
                            padding: 5px 0;
                            border-right: 0;
                            border-bottom: 1px solid #000;
                            background-color: #f4f4f4;
                        }
                        > div {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            > div {padding: 4px 20px;}
                        }
                    }
                }
            }
            > .error {
                > .listBox {
                    > div {
                        flex-direction: column;
                        padding-left: 10px;
                        > h4 {
                            width: 100%;
                            padding: 0 0 5px 0;
                        }
                        > h5 {width: 100%;}
                    }
                }
            }
            > .serviceList {
                > div {
                    > h3 {
                        width: 5%;
                        font-size: 16px;
                    }
                    > div {
                        width: 95%;
                        > div {
                            width: 100%;
                            > h4, h5 {
                                font-size: 16px;
                                padding: 4px 0 4px 5px;
                                white-space: wrap;
                            }
                            > h4 {width: 30%;}
                            > h5 {width: 70%;}
                        }
                    }
                }
            }
            > .serviceOutline {
                > .listBox {
                    > h4 {font-size: 18px;}
                    > div {
                        > h4, h5 {
                            font-size: 16px;
                            padding: 4px 0 4px 4px;
                        }
                        > h4 {width: 25%;}
                        > h5 {
                            width: 75%;
                            font-size: 15px;
                        }
                    }
                    > .serviceKey {
                        > div {
                            width: 25%;
                            padding: 4px 0 4px 4px;
                            > h4, h5 {font-size: 16px;}
                            > button {
                                font-size: 15px;
                                padding: 4px 2px;
                                white-space: wrap;
                            }
                        }
                        > h5 {
                            font-size: 15px;
                            width: 75%;
                        }
                    }
                }
            }
        }
        > .openApiExam {
            > div {
                > .searchBox {
                    > input {
                        font-size: 16px;
                        padding: 4px 6px;
                    }
                    > button {
                        font-size: 16px;
                        padding: 4px 6px;
                    }
                }
                > .searchBox2 {
                    > div {
                        > h2 {font-size: 16px;}
                        > input {
                            font-size: 16px;
                            padding: 4px 6px;
                        }
                    }
                    > button {
                        font-size: 16px;
                        padding: 4px 6px;
                    }
                }
                > .searchResult {
                    > .imgBox {
                        width: 25%;
                        > img {width: 60%;}
                    }
                    > .infoBox {
                        width: 75%;
                        > div {
                            > h4, h5 {
                                padding: 2px;
                                font-size: 16px;
                            }
                            > h4 { width: 20%;}
                            > h5:nth-last-child(1) {width: 30%;}
                        }
                        > div:nth-last-child(1) {
                            > h4 {width: 20%;}
                            > h5 {width: 80%;}
                        }
                    }
                }
            }
        }
    }
}
/* 500px 이하 */
@media (max-width: 500px) {
    #openApiPage {
        > .section {
            > p {
                width: 90%;
            }
            > .apiList {
                width: 90%;
                > .listBox {
                    > h4 {font-size: 15px;}
                    > div {
                        > h5 {font-size: 15px;}
                        > div {
                            > div {
                                padding-left: 4px;
                                > h4 {font-size: 14px;}
                                > h5 {font-size: 14px;}
                            }
                        }
                    }
                }
            }
            > .error {
                width: 90%;
                > h4 {font-size: 16px;}
                > .listBox {
                    > div {
                        flex-direction: column;
                        padding-left: 4px;
                        > h4 {
                            font-size: 14px;
                            padding: 0 0 4px 0;
                        }
                        > h5 {
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
            > .serviceList {
                width: 90%;
                > div {
                    flex-direction: column;
                    > h3 {
                        width: auto;
                        border: 0;
                        border-bottom: 1px solid #000;
                        padding: 5px 0;
                    }
                    > div {
                        width: 100%;
                        > div {
                            flex-direction: column;
                            > h4, h5 {
                                width: auto;
                                font-size: 16px;
                                padding: 6px;
                            }
                            > h4 {
                                border: 0;
                                border-bottom: 1px solid #000;
                                background-color: #f4f4f4;
                                font-weight: bolder;
                            }
                        }
                        > div:nth-last-child(1) {
                            > h4 {border-bottom: 1px solid #000;}
                        }
                    }
                }
            }
            > .serviceOutline {
                width: 90%;
                > .listBox {
                    > h4 {font-size: 18px;}
                    > div {
                        flex-direction: column;
                        > h4, h5 {
                            width: auto;
                            font-size: 16px;
                            padding: 4px 0 4px 10px;
                        }
                        > h4 {
                            border: 0;
                            border-bottom: 1px solid #000;
                            background-color: #f4f4f4;
                        }
                    }
                    > .serviceKey {
                        > div {
                            width: 100%;
                            flex-direction: row;
                            width: auto;
                            padding: 4px 10px;
                            border: 0;
                            border-bottom: 1px solid #000;
                            background-color: #f4f4f4;
                            align-items: center;
                            justify-content: space-between;

                            > h4 {
                                font-size: 16px;
                                width: 48%;
                            }
                            > button {
                                width: 48%;
                                font-size: 16px;
                                padding: 2px;
                                margin: 0;
                            }
                        }
                        > h5 {
                            font-size: 16px;
                            width: auto;
                        }
                    }
                }
            }
        }
        > .openApiExam {
            > div {
                > h1 {
                    font-size: 16px;
                }
                > .searchBox {
                    > input {font-size: 15px;}
                    > button {font-size: 15px;}
                }
                > .searchBox2 {
                    > div {
                        > h2 {
                            width: 35%;
                            font-size: 15px;
                        }
                        > input {
                            width: 15%;
                            font-size: 15px;
                            padding: 2px 4px;
                        }
                    }
                    > button {
                        font-size: 15px;
                        padding: 2px 4px;
                    }
                }
                > .searchResult {
                    flex-direction: column;
                    > .imgBox {
                        width: 100%;
                        border: 0;
                        border-bottom: 1px solid #000;
                        > img {width: 50%;}
                    }
                    > .infoBox {
                        width: 100%;
                        > div {
                            > h4, h5 {font-size: 15px;}
                            > h4 {width: 20%;}
                            > h5:nth-last-child(1) {width: 30%;}
                        }
                        > div:nth-last-child(1) {
                            > h4 {width: 20%;}
                            > h5 {width: 80%;}
                        }
                    }
                }
            }
        }
    }
}