#Encryption {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
        display: flex;
    }
    > .encodeBox {

        > div {
            flex-direction: column;
            > .encoding {
                display: flex;
                padding: 10px;
                align-items: center;
                justify-content: space-between;
                > h4 {
                    width: 10%;
                    font-size: 20px;
                    font-weight: bolder;
                    text-align: center;
                    white-space: nowrap;
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 75%;
                    > div {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        > h5 {
                            width: 35%;
                            text-align: center;
                            font-size: 16px;
                            white-space: nowrap;
                        }
                        > input {
                            margin-left: 10px;
                            font-size: 16px;
                            padding: 4px 10px;
                            width: 65%;
                            border: 2px solid #b8b8b8;
                            border-radius: 5px;
                        }
                        > select {
                            width: 65%;
                            max-width: 800px;
                            min-width: 100px;
                            margin-left: 10px;
                            font-size: 16px;
                            padding: 4px 10px;
                            border: 2px solid #b8b8b8;
                            border-radius: 5px;
                        }
                        > input:focus {
                            border: 2px solid #000000;
                        }
                        > select:focus {
                            border: 2px solid #000000;
                        }
                    }
                    > div:nth-child(2) {
                        margin-top: 4px;
                    }
                }
                > button {
                    width: 10%;
                    height: 100%;
                    padding: 6px 10px;
                    border-radius: 5px;
                }
            }
            > p {
                display: flex;
                margin-top: 10px;
                font-size: 16px;
                font-weight: bolder;
                padding: 10px;
                white-space: nowrap;
                align-self: center;
            }
            > .oneWayTable {
                border: 1px solid #111;
                border-collapse: collapse;
                margin: 20px 10px;
                > tr {
                    > td {
                        border: 1px solid #111;
                        font-size: 16px;
                        padding: 4px 10px;
                        text-align: start;
                    }
                }
                > tr:nth-child(1) {
                    td {
                        font-size: 20px;
                        font-weight: bolder;
                        text-align: center;
                    }
                }
            }
            > .RSABox {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
                border-bottom: 1px solid #000;

                > .userBox {
                    display: flex;
                    width: 48%;
                    flex-direction: column;

                    button {
                        border-radius: 5px;
                    }

                    > div {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        > h1 {
                            width: 20%;
                            font-size: 16px;
                            font-weight: bolder;
                        }
                        > p {
                            font-size: 20px;
                            font-weight: bolder;
                            margin-right: 20px;
                        }
                        > button {
                            padding: 4px 8px;
                        }
                    }
                    > .key {
                        display: flex;
                        justify-content: space-between;

                        > input {
                            width: 80%;
                            font-size: 16px;
                            padding: 4px 10px;
                            border: 2px solid #b8b8b8;
                            border-radius: 5px;
                        }
                        > input:focus {
                            border: 2px solid #000000;
                        }
                    }
                    > .btn {
                        justify-content: space-between;
                        > div {
                            display: flex;
                            justify-content: space-between;
                            width: 80%;

                            > button {
                                width: 48%;
                                padding: 6px;
                                white-space: wrap;
                            }
                            > .btnA {
                                border: 2px solid #00a82a;
                                color: #00a82a;
                            }
                            > .btnA:hover {
                                background-color: #00a82a;
                                color: white;
                            }
                            > .btnB {
                                border: 2px solid #0029be;
                                color: #0029be;
                            }
                            > .btnB:hover {
                                background-color: #0029be;
                                color: white;
                            }
                        }
                    }
                    > .trans {
                        justify-content: space-between;
                        > div {
                            display: flex;
                            width: 80%;
                            justify-content: space-between;
                            > input {
                                width: 80%;
                                font-size: 16px;
                                padding: 4px 10px;
                                border: 2px solid #b8b8b8;
                                border-radius: 5px;
                            }
                            > input:focus {
                                border: 2px solid #000000;
                            }
                            > button {
                                width: 18%;
                                padding: 4px 8px;
                            }
                        }
                    }
                }
            }
            > .RSABox:nth-last-child(1) {
                border: 0;
            }
        }
    }
}
/* 1200px 이하 */
@media (max-width: 1200px) {
    #Encryption > .encodeBox {
        > div {
        }
    }
}
/* 800px 이하 */
@media (max-width: 800px) {
    #Encryption > .encodeBox {
        > div {
            > .encoding {
                > h4 {
                    margin: 0;
                }
                > button {
                    padding: 6px;
                }
            }
            > .oneWayTable {
                > tr {
                    > td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4) {
                        max-width: 40px;
                        white-space: nowrap;
                        overflow-x: auto;
                    }
                }
            }
            > .RSABox {
                flex-direction: column;
                > .userBox {
                    width: 100%;
                    padding: 10px 0;
                    > .btn {
                        > div {
                            flex-direction: column;
                            > button {
                                width: 100%;
                                padding: 6px;
                                white-space: wrap;
                                margin: 4px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* 500px 이하일 때 */
@media (max-width: 500px) {
    #Encryption > .encodeBox {
        > div {
            > .encoding {
                flex-direction: column;
                > div {
                    width: 100%;
                    > div {
                        margin: 5px 0;
                    }
                }
                > button {
                    width: 100%;
                    padding: 8px;
                    margin: 10px 0 0 0;
                }
            }
            > .oneWayTable {
                > tr {
                    > td {
                        font-size: 14px;
                        padding: 4px 2px;
                    }
                    > td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4) {
                        white-space: nowrap;
                        overflow-x: auto;
                    }
                }
                > tr:nth-child(1) {
                    td {
                        font-size: 14px;
                        font-weight: bolder;
                        text-align: center;
                    }
                }
            }
        } 
    }
}