@font-face {
    font-family: 'GangwonEduPowerExtraBoldA';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduPowerExtraBoldA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

#visitorTracking {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
        display: flex;
        box-sizing: border-box;
    }

    #description {
        width: 100%;
        display: flex;
        justify-content: start;
        flex-direction: column;
    }
    #analytics {
        width: 100%;
        padding: 20px 20px 0 20px;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #FFFFFF;

        p, h1, h2, span {
            font-family: 'GangwonEduPowerExtraBoldA';
        }

        > #daily {
            flex-direction: column;
            width: 48%;
            margin-bottom: 20px;
            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: 2px 2px 5px #9e9e9e,
                        inset 1px 1px 1px #ebebeb,
                        inset -2px -2px 2px #ebebeb;

            > h1 {
                font-size: 18px;
                font-weight: bolder;
                padding: 16px 16px 8px 16px;
                color: #212121;
            }

            > div {
                width: 100%;
                padding: 10px;
            }
        }

        > #summary {
            flex-direction: column;
            justify-content: space-between;
            width: 48%;
            margin-bottom: 20px;

            > div > h1 {
                font-size: 18px;
                font-weight: bolder;
                padding: 16px 16px 8px 16px;
                color: #212121;
            }
            > #visitors {
                flex-direction: column;
                background-color: #FFFFFF;
                border-radius: 10px;
                box-shadow: 2px 2px 5px #9e9e9e,
                        inset 1px 1px 1px #ebebeb,
                        inset -2px -2px 2px #ebebeb;
                margin-bottom: 20px;

                > #visitorBox {
                    padding: 4%;
                    width: 100%;
                    justify-content: space-between;

                    > div {
                        width: 30%;
                        justify-content: space-between;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px;
                        border-radius: 10px;
                        box-shadow: 2px 2px 5px #9e9e9e,
                                -1px -1px 1px #ebebeb,
                                inset -1px -1px 2px #ebebeb;
                        
                        > h1 {
                            font-size: 34px;
                            font-weight: bolder;
                            text-align: center;
                            padding-top: 16px;
                            color: #212121;
                            text-shadow: 1px 1px 1px #c6c6c6;
                        }
                        > h2 {
                            font-size: 20px;
                            font-weight: bolder;
                            text-align: center;
                            padding: 0px 20px 4px 20px;
                            color: #212121;
                            text-shadow: 1px 1px 1px #c6c6c6;
                        }
                    }
                    > #today {
                        background-color: #FFD93D;
                    }
                    > #week {
                        background-color: #4D96FF;
                    }
                    > #month {
                        background-color: #FF6B6B;
                    }
                }
            }
            > #division {
                flex-direction: column;
                background-color: #FFFFFF;
                border-radius: 10px;
                box-shadow: 2px 2px 5px #9e9e9e,
                        inset 1px 1px 1px #ebebeb,
                        inset -2px -2px 2px #ebebeb;

                > div {
                    width: 100%;
                    padding: 4%;
                    justify-content: space-between;

                    > div {
                        width: 45%;
                        justify-content: center;
                        align-items: center;

                        > img {
                            width: 70px;
                            height: 70px;
                            margin-right: 20px;
                        }
                        > p {
                            font-size: 30px;
                            color: #212121;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }
        > #doughnut {
            flex-direction: column;
            width: 48%;
            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: 2px 2px 5px #9e9e9e,
                        inset 1px 1px 1px #ebebeb,
                        inset -2px -2px 2px #ebebeb;
            margin-bottom: 20px;

            > h1 {
                font-size: 18px;
                font-weight: bolder;
                padding: 16px 16px 8px 16px;
                color: #212121;
            }
            .chartWrap{
                position: relative;
                padding: 5%;
                justify-content: space-between;
                
                .chart {
                    position: relative;
                    width: 60%;
                    padding-bottom: 60%;
                    border-radius: 50%;
                    transition: 0.3s;
                    background:lightgray;
                    display:inline-block;
                    box-shadow: 2px 2px 5px #9e9e9e,
                            inset 1px 1px 1px #ebebeb,
                            inset -1px -1px 1px #ebebeb;

                    .center{
                        content:'';
                        background: #FFFFFF;
                        position: absolute;
                        top:50%; left:50%;
                        width:50%; height:50%;
                        border-radius: 50%;
                        transform: translate(-50%, -50%);
                        box-shadow: inset 2px 2px 5px #9e9e9e,
                                inset -2px -2px 2px #ebebeb;
                    }
                    .chart-bar {
                        position: absolute;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        color: #212121;
                        background-color: transparent;
                        text-align: center;
                        width: 60px; /* 레이블 너비 */
                        height: 60px; /* 레이블 높이 */
                        transform: translate(-50%, -50%); /* 레이블 중심에 맞춤 */
                        font-family: 'GangwonEduPowerExtraBoldA';
                    }
                }
                
                .chartInfo {
                    width: 45%;
                    justify-content: end;
                    align-items: center;
                    > .infoBox {
                        width: 80%;
                        flex-direction: column;
                        padding: calc(100% / 20);
                        border-radius: 10px;
                        box-shadow: 4px 4px 5px #c1c1c1,
                            inset -2px -2px 5px #dfdfdf,
                            -2px -2px 5px #f1f1f1;

                        > div {
                            width: 100%;
                            position: relative;
                            justify-content: flex-start;
                            margin: 4px 0;
                            cursor: pointer;
    
                            > span {
                                display: flex;
                                width: 20px;
                                height: 20px;
                                margin-right: 6px;
                            }
                            > p {
                                display: flex;
                                padding-top: 6px;
                            }
                            > .city {
                                width: 100%;
                                display: none;
                                flex-direction: column;
                                position: absolute;
                                top: 30px;
                                padding: 3%;
                                background-color: rgb(255, 255, 255, 0.9);
                                border: 1px solid #000;
                                z-index: 1;
            
                                > div {
                                    justify-content: space-between;
                                    > p {
                                        display: flex;
                                        font-size: 12px;
                                        text-align: left;
                                        padding-top: 6px;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                        > div:hover {
                            font-weight: bolder;
                            .city{
                                display: flex;
                            } 
                        }
                        > div:nth-child(1) > span {background-color: #FF6B6B;}
                        > div:nth-child(2) > span {background-color: #4D96FF;}
                        > div:nth-child(3) > span {background-color: #6BCB77;}
                        > div:nth-child(4) > span {background-color: #FFD93D;}
                        > div:nth-child(5) > span {background-color: #FFF89A;}
                    }
                }
            }
        }
    }
}
/* 1000px 이하 */
@media (max-width: 1000px) {
    #visitorTracking {
        > #analytics {
            flex-direction: column;

            > #daily {
                width: 100%;
            }
            > #summary {
                width: 100%;
            }
            > #doughnut {
                width: 100%;
            }
        }
    }
}
/* 800px 이하 */
@media (max-width: 800px) {
}
/* 500px 이하일 때 */
@media (max-width: 500px) {
    #visitorTracking {
        > #analytics {
            > #daily {
                > h1 {
                    font-size: 16px;
                }
            }
            > #summary {
                > #visitors {
                    > h1 {
                        font-size: 16px;
                    }
                }
                > #division {
                    > h1 {
                        font-size: 16px;
                    }
                    > div {

                        > div {

                            > img {
                                width: 50px;
                                height: 50px;
                                margin-right: 10px;
                            }
                            > p {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
            > #doughnut {
                > h1 {
                    font-size: 16px;
                }
                .chartWrap {
                    //padding: 5px;
                    .chartInfo > .infoBox {
                        > div {
                            > span {
                                width: 15px;
                                height: 15px;
                            }
                            > p {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}