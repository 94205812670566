#searchEngine {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    > #listPage {
        display: flex;
        flex-direction: column;
        width: 90%;

        > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            
            h1 {
                display: flex;
                justify-content: center;
                width: 100%;
                font-size: 20px;
                font-weight: bolder;
                margin-bottom: 20px;
                padding-bottom: 8px;
                border-bottom: 1px solid #000;
            }
    
            > div {
                display: flex;
                justify-content: center;
            }
    
            > .searchBar {
                width: 100%;
                padding: 10px 0;
                > div {
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                    > input {
                        width: 68%;
                        font-size: 16px;
                        padding: 4px 10px;
                        margin-right: 4px;
                        border: 2px solid #b8b8b8;
                        border-radius: 10px;
                    }
                    > h5 {
                        display: flex;
                        width: 140px;
                        font-size: 16px;
                        font-weight: bolder;
                        align-items: center;
                        white-space: nowrap;
                    }
                    > button {
                        width: 10%;
                        white-space: nowrap;
                        padding: 0;
                    }
                }
            }
    
            > .example {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin: 10px 0;
    
                > h2 {
                    font-size: 14px;
                    font-style: italic;
                    padding: 4px 0;
                    color: #3e3e3e;
                }
            }
    
            > .postList {
                width: 100%;
                max-width: 1000px;
                flex-direction: column;
                margin: 10px 0;

                > #listBox {
                    display: flex;
                    flex-direction: column;

                    > .item {
                        display: flex;
                        border: 1px solid #000;
                        cursor: pointer;
                        > p {
                            display: flex;
                            align-self: center;
                            justify-content: center;
                            font-size: 20px;
                            font-weight: bolder;
                            min-width: 10%;
                        }
                        > div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 5px;
                            border-left: 1px solid #000;

                            > h2 {
                                display: flex;
                                font-size: 20px;
                                font-weight: bolder;
                                margin-bottom: 5px;
                                align-items: center;
                                > span {
                                    border: 1px solid red;
                                    padding: 2px 4px;
                                    font-size: 14px;
                                    color: red;
                                    margin-left: 4px;
                                }
                                > span::after {
                                    content: "%";
                                }
                            }
                        }
                    }
                    > .item:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
    
            > .postPaging {
                
                h1 {
                    font-size: 20px;
                    font-weight: bolder;
                    margin: 20px 0;
                }
            }
    
            > .postBtn {
                width: 100%;
                justify-content: end;
            }
        }
    }

    > #writePage {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 900px;

        h1 {
            display: flex;
            justify-content: center;
            width: 800px;
            font-size: 20px;
            font-weight: bolder;
            margin-bottom: 20px;
            padding-bottom: 8px;
            border-bottom: 1px solid #000;
        }

        > input {
            display: flex;
            width: 800px;
            font-size: 16px;
            padding: 6px 10px;
            margin: 10px 0;
        }
        > textarea {
            display: flex;
            width: 800px;
            min-height: 600px;
            font-size: 16px;
            padding: 6px 10px;
            resize: none;
        }

        > .postBtn {
            margin: 20px 0;
            display: flex;
            width: 800px;
            justify-content: end;

            > button {
                margin-left: 10px;
            }
        }
    }

    > #viewPage {
        display: flex;
        flex-direction: column;
        width: 90%;
        > div {
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1 {
                display: flex;
                justify-content: center;
                width: 100%;
                font-size: 20px;
                font-weight: bolder;
                margin-bottom: 20px;
                padding-bottom: 8px;
                border-bottom: 1px solid #000;
            }
    
            > input {
                display: flex;
                width: 100%;
                font-size: 20px;
                font-weight: bolder;
                padding: 6px 10px;
                margin: 10px;
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid #111;
            }
            > textarea {
                display: flex;
                width: 100%;
                min-height: 600px;
                font-size: 16px;
                padding: 6px 10px;
                resize: none;
                background-color: transparent;
                border: 0;
            }
    
            > .postBtn {
                margin: 20px 0;
                display: flex;
                width: 100%;
                justify-content: end;
    
                > button {
                    margin-left: 10px;
                }
            }
        }
    }
}
/* 500px 이하일 때 */
@media (max-width: 500px) {
    #searchEngine {
        > #listPage {
            > div {
                > .searchBar {
                    > div {
                        display: flex;
                        justify-content: center;
                        > input {
                            width: 50%;
                            margin: 0 4px;
                        }
                        > h5 {
                        }
                        > button {
                            width: 15%;
                        }
                    }
                }
                > .postList {
                    > #listBox {
                        > .item {
                            > p {
                                font-size: 16px;
                            }
                            > div {
                                > h2 {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}