.modalBox {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);

    .modal {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        background-color: white;
        overflow: hidden;
        box-shadow: 2px 2px 2px #4e4e4e;
    }
}

.register, .login {
    
    > .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        margin-bottom: 20px;
        background-color: #c0c0c0;
        border-bottom: 2px solid #828282;

        > h1 {
            font-size: 20px;
            font-weight: bolder;
        }
        > p {
            display: flex;
            align-self: flex-end;
            font-size: 20px;
            font-weight: bolder;
            cursor: pointer;
        }
    }
    > .input {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 5px 30px;

        > h2 {
            font-size: 18px;
            font-weight: bolder;
            align-self: flex-start;
            margin-bottom: 4px;
        }
        > input {
            padding: 6px 8px;
            font-size: 16px;
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            background-color: #f8f8f8;
        }
        h3 {
            font-size: 12px;
            align-self: flex-start;
            color: #6c6c6c;
            padding-top: 4px;
        }
    }
    > button {
        padding: 6px;
        font-size: 18px;
        font-weight: bolder;
        border: 1px solid #c3c3c3;
        border-radius: 4px;
        background-color: #e6e6e6;
        margin: 20px 30px;
        cursor: pointer;
        color: #000;
    }
    > button:hover {
        background-color: #c3c3c3;
    }
}