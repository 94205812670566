.colDiv {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .gameRule {
        padding: 15px;
        margin-top: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        width: 100%;
    
        h2 {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    
        h3 {
            font-size: 16px;
            margin-bottom: 8px;
            line-height: 1.5;
            color: #333;
    
            &::before {
                content: "※ ";
                font-weight: bold;
                color: #555;
            }
        }
    
        ul {
            padding-left: 20px;
            margin-bottom: 10px;
    
            li {
                font-size: 14px;
                color: #555;
                line-height: 1.4;
                margin-bottom: 5px;
                list-style-type: disc;
            }
        }
    }
    // 플레이어 심볼
    .symbol {
      display: flex;
      margin-top: 10px;
      font-size: 30px;
      font-weight: 600;
    }

    // 게임방 목록
.roomList {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.room {
  display: flex;
  flex-direction: column;
  justify-content: center; // 텍스트를 중앙에 배치
  align-items: center;
  width: 256px;
  height: 130px;
  padding: 15px;
  margin: 10px;
  border: 4px solid #333; // 두꺼운 테두리
  border-radius: 20px; // 둥근 모서리
  background-color: #f9f9f9; // 기본 배경색
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 0 #333, 0 6px 0px #000; // 입체감 있는 그림자
  font-family: 'Press Start 2P', cursive; // 도트 글꼴
  transition: background-color 0.3s, transform 0.1s;
  text-transform: uppercase; // 대문자 스타일

  h1{
    font-size: 31px;
    font-weight: bolder;
    color: white;
    text-align: center;
    text-shadow: 3px 0px #000, 0px 3px #000, -3px 0px #000, 0px -3px #000;
  }

  // 버튼의 도트 느낌을 위한 테두리 스타일
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed #666; // 도트 느낌의 테두리
    pointer-events: none;
  }

  // 방 버튼의 각 색상 정의
  &.usedRoom {
    background-color: #ffca03; // 노란색 배경
    border-color: #d4a500; // 테두리 색상
    box-shadow: 0 4px 0 #d4a500, 0 6px 0px #b38700; // 그림자 효과
  }

  &:hover {
    background-color: #e0e0e0; // 호버 시 배경색
    transform: translateY(-6px);
  }
}

// 도트 느낌의 다양한 배경색 스타일 추가
.room {
  &.color1 {
    background-color: #3b88c3;
    border-color: #2d6c9c;
    box-shadow: 0 4px 0 #2d6c9c, 0 6px 0px #1f4f75;
  }

  &.color2 {
    background-color: #ff6a6a;
    border-color: #d45858;
    box-shadow: 0 4px 0 #d45858, 0 6px 0px #b44646;
  }

  &.color3 {
    background-color: #71c671;
    border-color: #5da55d;
    box-shadow: 0 4px 0 #5da55d, 0 6px 0px #498549;
  }

  &.pink {
    background-color: #ffb6c1;
    border-color: #d4909a;
    box-shadow: 0 4px 0 #d4909a, 0 6px 0px #a46a73;
  }
}


}

#Tictactoe {
    width: 100%;
    max-width: 600px;
    background-color: #faf3e0; // 밝은 도트 게임 배경색
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    border: 8px solid #333; // 두꺼운 외곽 테두리

    .ctnRow {
        display: flex;
        justify-content: space-between;

        .btnTictactoe {
          width: 200px;
          height: 200px;
          background-color: #fff; // 버튼 배경색
          border: 4px solid #333; 
          font-size: 48px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s;
          border-radius: 0;

          &:hover {
            background-color: #f0e68c; // 버튼 위에 마우스를 올릴 때 배경색 변경
          }
          &.circle {
              
            &::before {
              content: '';
              width: 120px; // 원의 너비
              height: 120px; // 원의 높이
              border-radius: 50%; // 둥근 원
              border: 8px solid #333; // 원의 테두리
              position: absolute;
            }
          }

          &.cross {
            &::before,
            &::after {
              content: '';
              position: absolute;
              width: 8px;
              height: 160px;
              background-color: #333; // X의 색상
            }
      
            &::before {
              transform: rotate(45deg); // 대각선 방향
            }
      
            &::after {
              transform: rotate(-45deg); // 반대 대각선 방향
            }
          }
        }
    }
}
/* 800px 이하 */
@media (max-width: 800px) {
  .roomList {
    flex-direction: column;

    > .room {
      width: 100%;
      height: 70px;
      flex-direction: row;
      margin: 10px 0;
      > h1 {
        margin: 0 10px;
      }
    }
  }
  #Tictactoe {
    max-width: 450px;
    .btnTictactoe {
      width: 150px!important;
      height: 150px!important;
      &.circle {
        &::before {
          width: 80px!important;
          height: 80px!important;
        }
      }
      &.cross {
        &::before,
        &::after {
          height: 110px!important;
        }
      }
    }
  }
}
/* 500px 이하일 때 */
@media (max-width: 500px) {
  .colDiv {
    .symbol {
      font-size: 20px;
    }
  }
  #Tictactoe {
    max-width: 300px;
    border: 4px solid #333;
    .btnTictactoe {
      width: 100px!important;
      height: 100px!important;
      &.circle {
        &::before {
          width: 50px!important;
          height: 50px!important;
        }
      }
      &.cross {
        &::before,
        &::after {
          height: 70px!important;
        }
      }
    }
  }
}